exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-advocate-for-you-js": () => import("./../../../src/pages/advocate-for-you.js" /* webpackChunkName: "component---src-pages-advocate-for-you-js" */),
  "component---src-pages-behaviour-support-js": () => import("./../../../src/pages/behaviour-support.js" /* webpackChunkName: "component---src-pages-behaviour-support-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-studies-details-js": () => import("./../../../src/pages/case-studies-details.js" /* webpackChunkName: "component---src-pages-case-studies-details-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-details-js": () => import("./../../../src/pages/course-details.js" /* webpackChunkName: "component---src-pages-course-details-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-event-details-js": () => import("./../../../src/pages/event-details.js" /* webpackChunkName: "component---src-pages-event-details-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-functional-capacity-building-js": () => import("./../../../src/pages/functional-capacity-building.js" /* webpackChunkName: "component---src-pages-functional-capacity-building-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-home-modifications-js": () => import("./../../../src/pages/home-modifications.js" /* webpackChunkName: "component---src-pages-home-modifications-js" */),
  "component---src-pages-home-remote-visits-js": () => import("./../../../src/pages/home-remote-visits.js" /* webpackChunkName: "component---src-pages-home-remote-visits-js" */),
  "component---src-pages-home-safety-assessments-js": () => import("./../../../src/pages/home-safety-assessments.js" /* webpackChunkName: "component---src-pages-home-safety-assessments-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kylie-bell-js": () => import("./../../../src/pages/kylie-bell.js" /* webpackChunkName: "component---src-pages-kylie-bell-js" */),
  "component---src-pages-life-style-choices-that-can-extend-your-life-js": () => import("./../../../src/pages/life-style-choices-that-can-extend-your-life.js" /* webpackChunkName: "component---src-pages-life-style-choices-that-can-extend-your-life-js" */),
  "component---src-pages-membership-levels-js": () => import("./../../../src/pages/membership-levels.js" /* webpackChunkName: "component---src-pages-membership-levels-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-and-services-js": () => import("./../../../src/pages/products-and-services.js" /* webpackChunkName: "component---src-pages-products-and-services-js" */),
  "component---src-pages-service-assistive-technology-js": () => import("./../../../src/pages/service-assistive-technology.js" /* webpackChunkName: "component---src-pages-service-assistive-technology-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-what-is-ot-js": () => import("./../../../src/pages/what-is-ot.js" /* webpackChunkName: "component---src-pages-what-is-ot-js" */),
  "component---src-pages-wheelchair-and-seating-js": () => import("./../../../src/pages/wheelchair-and-seating.js" /* webpackChunkName: "component---src-pages-wheelchair-and-seating-js" */)
}

